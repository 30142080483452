import type { ThemeUIStyleObject as TSO } from 'theme-ui';
import { keyframes } from '@emotion/react';
import { LogoBarProps as Props } from './types';
import { getSpacing } from '@lib/utils';

export const useStyles = ({ logoGroups, order }: Props): typeof Styles => {
  // build keyframes and animation delays dynamically
  const allGroups = logoGroups?.items || [];
  const groupLength = logoGroups?.items?.length || 0;
  const autoplayDuration = 5;
  const animDuration = groupLength * autoplayDuration;
  const lgGroup = logoGroups.items.some(
    (e) => e.logosCollection.items.length > 4
  );
  const logoFadeIn = keyframes({
    '0%': { opacity: 0 },
    '5%': { opacity: 1 },
    [`${100 / groupLength}%`]: { opacity: 1 },
    [`${100 / groupLength + 5}%`]: { opacity: 0 },
    '100%': { opacity: 0 },
  });
  const textSlideUp = keyframes({
    '0%': { transform: 'rotateX(-90deg)', opacity: 0 },
    '5%': { transform: 'rotateX(0deg)', opacity: 1 },
    [`${100 / groupLength}%`]: { transform: 'rotateX(0deg)', opacity: 1 },
    [`${100 / groupLength + 3}%`]: { transform: 'rotateX(-90deg)' },
    [`${100 / groupLength + 5}%`]: { transformOrigin: 'bottom', opacity: 0 },
    '100%': { transform: 'rotateX(-90deg)', opacity: 0 },
  });
  const logoNoMotionFade = keyframes({
    '0%': { visibility: 'visible' },
    [`${100 / groupLength}%`]: { visibility: 'hidden' },
  });
  const animDelay = () =>
    allGroups.reduce((prev, curr, i) => {
      const animObj = {
        '@media(prefers-reduced-motion)': {
          visibility: 'hidden',
          animation: 'none',
          '&:first-of-type': {
            visibility: 'visible',
          },
        },
        ...prev,
        [`&:nth-of-type(${i + 1})`]: {
          animationDelay: `${i * (animDuration / groupLength)}s`,
        },
      };
      return animObj;
    }, {});

  const Styles = {
    ROOT: {
      contain: 'layout',
      paddingBlockStart: [4, 5],
      paddingBlockEnd: [4, 5],
      paddingInlineStart: 11,
      paddingInlineEnd: 11,
      backgroundColor: 'grey.1',
      marginBlockStart: getSpacing(order),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: [0, 5],
    } as TSO,

    HEADING: {
      alignSelf: 'center',
      h2: {
        fontSize: 2,
        fontWeight: 'heading',
        lineHeight: 1.4,
        paddingInlineEnd: ['0px', 7],
      },
    } as TSO,

    TEXT_LIST: {
      position: 'relative',
      display: ['block', 'inline'],
      marginInlineStart: ['0px', '.43ch'],
      verticalAlign: 'bottom',
      marginBlockEnd: [4, '0px'],
      span: {
        textAlign: 'center',
        inlineSize: '100%',
        display: ['block', 'inline-block'],
        position: 'absolute',
        textTransform: 'lowercase',
        transform: 'rotateX(0deg)',
        transformOrigin: 'top',
        animation: `${
          groupLength > 1 ? textSlideUp : logoNoMotionFade
        } ${animDuration}s both infinite`,

        ...animDelay(),
      },
    } as TSO,

    MEDIA: {
      position: 'relative',
      inlineSize: '100%',
      blockSize: '100%',
      paddingBlockEnd: lgGroup ? ['300px', 9, 6] : [9, 6],
      '@media screen and (min-width:975px) and (max-width:1280px)': {
        paddingBlockEnd: lgGroup && 6,
      },
    } as TSO,

    LIST: {
      display: 'flex',
      placeItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: [0, 4, 6],
      justifyContent: 'center',
      alignItems: 'center',
      listStyle: 'none',
      animation: `${
        groupLength > 1 ? logoFadeIn : logoNoMotionFade
      } ${animDuration}s infinite both`,
      position: 'absolute',
      inlineSize: '100%',
      blockSize: '100%',
      textAlign: 'center',

      ...animDelay(),

      li: {
        blockSize: '45px',
        inlineSize: '124px',
      },
    } as TSO,

    CTA: {
      marginBlockStart: 4,
    } as TSO,

    LOGO: {
      height: '100%',
      img: {
        blockSize: '45px',
        minInlineSize: '124px',
      },
    } as TSO,
  };

  return Styles;
};

import type { FC } from 'react';
import type { LogoBarProps as Props } from './types';

import { Block, Link } from '#ui';
import { useStyles } from './logobar.styles';
import { P } from '#ui';
import ResponsiveMedia from '@components/ResponsiveMedia';
import { propsValidator } from '@lib/utils';

const LogoBar: FC<Props> = ({ heading, logoGroups, cta, order, ...entry }) => {
  const valid = propsValidator(entry, {
    heading,
    logoGroups: logoGroups.items,
  });

  if (!valid) return null;

  const groups = logoGroups.items;
  const { destination, ctaCopy } = { ...cta };
  const Styles = useStyles({ logoGroups, order });

  return(
    <section sx={Styles.ROOT}>
      <Block sx={Styles.HEADING}>
        <P as="h2">
          <span>{heading}</span>
          <span role="list" sx={Styles.TEXT_LIST}>
            {groups.map((logoGroup, i) => {
              const { industry } = logoGroup
              return (<span role="listitem" key={i}>{industry}</span>)
            })}
          </span>
        </P>
      </Block>
      <Block sx={Styles.MEDIA}>
        {groups.map((logoGroup, i) => {
          const items = logoGroup.logosCollection.items;

          return (
            items?.length && 
            <ul key={i} sx={Styles.LIST}>
              {items.map((image, i) => {
                if (!image?.desktopAsset?.url) return null;
                return (
                  <li key={i}>
                    <ResponsiveMedia
                      sizes={[50, 25]}
                      sx={Styles.LOGO}
                      {...image}
                    />
                  </li>)
              })}
            </ul>
          )
        })}
      </Block>
      {cta && 
        <Link
          withIcon
          href={destination || undefined}
          openInNewTab={cta.openInNewTab}
          sx={Styles.CTA}
        >
          {ctaCopy}
        </Link>
      }
    </section>
  )
}

export default LogoBar;
